
import BStepItem from 'buetify/lib/components/steps/BStepItem';
import BSteps from 'buetify/lib/components/steps/BSteps';
import { defineComponent } from 'vue';
import UserCogIcon from '../../../../../components/icons/UserCogIcon';
import UserIcon from '../../../../../components/icons/UserIcon';
import UsersIcon from '../../../../../components/icons/UsersIcon';

export default defineComponent({
	name: 'steps-icons-example',
	components: {
		BSteps,
		BStepItem
	},
	setup() {
		return {
			UserIcon,
			UserCogIcon,
			UsersIcon
		};
	}
});
