
import { BSelect, BSwitch } from 'buetify/lib/components';
import BField from 'buetify/lib/components/form/field';
import BHorizontalDivider from 'buetify/lib/components/layout/divider/BHorizontalDivider';
import BStepItem from 'buetify/lib/components/steps/BStepItem';
import BSteps, { StepLabelPosition, StepsMobileMode, StepsSize } from 'buetify/lib/components/steps/BSteps';
import { defineComponent, shallowRef } from 'vue';

interface Option<T> {
	value: T;
	text: string;
}

const sizes: Option<StepsSize>[] = [
	{
		value: 'is-small',
		text: 'Small'
	},
	{
		value: '',
		text: 'Default'
	},
	{
		value: 'is-medium',
		text: 'Medium'
	},
	{
		value: 'is-large',
		text: 'Large'
	}
];

const positions: Option<StepLabelPosition>[] = [
	{
		value: '',
		text: 'Default'
	},
	{
		value: 'is-right',
		text: 'Right'
	},
	{
		value: 'is-left',
		text: 'Left'
	}
];

const mobileModes: Option<StepsMobileMode>[] = [
	{
		value: '',
		text: 'Default'
	},
	{
		value: 'compact',
		text: 'Compact'
	},
	{
		value: 'minimal',
		text: 'Minimal'
	}
];

export default defineComponent({
	name: 'simple-steps-example',
	components: {
		BSteps,
		BStepItem,
		BSelect,
		BSwitch,
		BField,
		BHorizontalDivider
	},
	setup() {
		const showSocial = shallowRef(false);
		const isRounded = shallowRef(false);
		const isAnimated = shallowRef(false);
		const isClickable = shallowRef(false);
		const useSuccess = shallowRef(false);
		const size = shallowRef<StepsSize>('');
		const labelPosition = shallowRef<StepLabelPosition>('');
		const activeStep = shallowRef(0);
		const mobileMode = shallowRef<StepsMobileMode>('');

		return {
			labelPosition,
			positions,
			isClickable,
			useSuccess,
			showSocial,
			isRounded,
			isAnimated,
			size,
			sizes,
			activeStep,
			mobileMode,
			mobileModes
		};
	}
});
